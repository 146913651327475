import React, { useRef, useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './upload-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import MultiSelectDropdown from '../../component/Common/CheckboxDropdown'
import ReactQuill from 'react-quill'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import Cookies from 'js-cookie';
import { set } from 'lodash';
import { toast } from 'react-toastify';
import { SubmissionStatus, ReviewStatus, ChooseActionsType, ActivityLkpcd, AdhocChecklistId, CheckList_Type_Lkpcd } from '../../utils/statusConstantValues';
import * as XLSX from 'xlsx';

const UploadReportInventory = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState<boolean>(false)
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedMCOPlanDropdown, setSelectedMCOPlanDropdown] = useState('');
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isRemoveFileModalOpen, setRemoveFileModalOpen] = useState(false);
    const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
    const [fileValue, setFileValue] = useState<FileWithMetadata[]>([]);
    const [removingFileName, setRemovingFileName] = useState('');
    const [uploadingFileTypeLKPCDList, setUploadingFileTypeLKPCDList] = useState<any>([])
    const { getMasterTableLookupValueData, getMasterTableLookupData } = useLooksUpData();
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const [uploadReportNotes, setUploadReportNotes] = useState('');
    const [uploadNewReport, setUploadNewReport] = useState<any>({
        mctReportInstanceSid: '',
        programReportSid: '',
        mcoName: '',
        reportName: '',
        referenceNumber: '',
        frequency: '',
        submissionModeLkpcd: '',
        reportType: '',
        startDate: '',
        endDate: '',
        originalDueDate: '',
        submissionType: '',
        submissionDate: '',
        reportUploadChecklist: '',
        file: '',
        activityNotes: uploadReportNotes || '',
        // submissinStatus: 'Submitted',
        currentGroup: '',
        currentSubmission: '',
        reviewStatus: '',
        createdBy: loggedInUserDetails.userEmailAddress || 'MCO User',
        createdDate: moment(new Date()).format('MM/DD/YYYY hh:mm A'),
        updatedBy: loggedInUserDetails.userEmailAddress || 'MCO User',
        updatedDate: moment(new Date()).format('MM/DD/YYYY hh:mm A'),
        fileType: '',
        uploadFiles: [],
        uploadTypeLkpcd: [],
        description: '',
        submissionNumber: '',
        submsnStatus: '',
        period: '',
        reviewNumber: ''
    });
    const ReportInventoryService = useReportInventoryService();
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [submissionModeList, setSubmissionModeList] = useState<any>([])
    const [listOfExistingReports, setListOfExistingReports] = useState<string[]>([]);
    const [reportNameOptions, setReportNameOptions] = useState<any[]>([]);
    const [reporPlanOptions, setReportPlanOptions] = useState<any[]>([]);
    const [instanceRecords, setInstaceRecords] = useState<any[]>([]);
    const [uniqueInstanceNameRecords, setUniqueInstanceNameRecords] = useState<any[]>([]);
    const [instanceDropdownRecords, setInstaceDropdownRecords] = useState<any[]>([]);
    const [periodDropdownRecords, setPeriodDropdownRecords] = useState<any[]>([]);
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [mcReportList, setMcReportList] = useState<any>([])
    const [mcProgramReportDate, setMcProgramReportDate] = useState<any>({});
    const [checklistItems, setChecklistItems] = useState<any>([])
    const [selectedChecklistItemIds, setSelectedChecklistItemIds] = useState<number[]>([]);
    const [latestActivityData, setLatestActivityData] = useState<any>(null)
    const [V_DB_FLAG, set_V_DB_FLAG] = useState('');
    const [uploadedFileList, setUploadedFileList] = useState<any>([])
    const [submissionNumber, setSubmissionNumber] = useState(0);
    const [reviewNumber, setReviewNumber] = useState(0);
    const [fileValidateErrors, setFileValidateErrors] = useState([])
    const [isFileValidateModalOpen, setIsFileValidateModalOpen] = useState(false);

    const fetchGetMasterTableLookupData = async () => {
        try {
            getMasterTableLookupData().then((response: any) => {
                if (response) {

                    let filteredMcPlan;
                    if (loggedInUserDetails.organizationUnitCode === "NEDHHS") {
                        filteredMcPlan = response.data.mc_plan;
                    } else {
                        filteredMcPlan = response.data.mc_plan.filter((item: { organizationUnitCode: string }) =>
                            item.organizationUnitCode === loggedInUserDetails.organizationUnitCode
                        );
                    }

                    const mcPlanOptions = filteredMcPlan.map((item: { planId: string, planName: string, organizationUnitCode: string }) => ({
                        value: item.planId,
                        label: item.planName,
                        org_unit_code: item.organizationUnitCode
                    }));

                    const mcReportName = response.data.mc_program_reports.map((dt: any) => dt.reportName)
                    setListOfExistingReports(mcReportName);

                    const mcReportingTypeLkpcd = response.data.mc_program_reports.filter(
                        (dt: any) => dt.reportingTypeLkpcd === 'D'
                    );

                    const mcProgramReportOptions = mcReportingTypeLkpcd.map((item: { programReportSid: string, reportName: string, stateReferenceNumber: string, reportingTypeLkpcd: string }) => ({
                        value: item.programReportSid,
                        label: item.reportName,
                        stateReferenceNumber: item.stateReferenceNumber,
                        reportingTypeLkpcd: item.reportingTypeLkpcd
                    }));

                    setReportPlanOptions(mcPlanOptions);
                    mcPlanOptions.filter((item: any) => {
                        if (item.org_unit_code === loggedInUserDetails.organizationUnitCode) {
                            setUploadNewReport((prevState: any) => ({
                                mcoName: item.value
                            }));
                            getAllInstaceRecordsForUpload(item.value);
                        }
                    });
                    setReportNameOptions(mcProgramReportOptions);
                }

            })

        } catch (e) {
            console.log(e);
        }
    }

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            getMasterTableLookupValueData().then((response: any) => {
                if (response) {

                    const filterAndMapData = (group: string, table: string, column: string) => {
                        return response.data
                            .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                            .map((code: any) => ({
                                lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                                lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                                lkpcd_group: code.lkpcd_group,
                            }));
                    };

                    const mcReportType = filterAndMapData('AL', 'MC_PROGRAM_REPORT', 'REPORTING_TYPE_LKPCD');

                    const dropdown = mcReportType.map((code: any) => {
                        return {
                            label: `${code.lkdmn_lkpcd_desc}`,
                            value: code.lkpdmn_lkpcd,
                            group: code.lkpcd_group
                        }
                    })

                    const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MCT_REPORT_INSTANCE', 'LAST_SUBMSN_STATUS_LKPCD');
                    const mcSubmisstionMode = filterAndMapData('AD', 'MC_PROGRAM_REPORT', 'REPORT_SBMSN_MODE_LKPCD');
                    const mcUploadingFileTypeList = filterAndMapData('MC', 'MCT_REPORT_ATTACHMENT', 'UPLOAD_TYPE_LKPCD');

                    setReportTypeList(dropdown);
                    setSubmissionModeList(mcSubmisstionMode);
                    setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                    setUploadingFileTypeLKPCDList(mcUploadingFileTypeList);

                }
            })

        } catch (e) {
            console.log(e);
        }
    }

    const getAllInstaceRecordsForUpload = async (planId: any) => {
        try {
            const response = await ReportInventoryService.getAllInstaceRecordsForUpload(planId);
            if (response) {
                const { data } = response;

                setInstaceRecords(data);

                // const dropdown = response.data.map((code: any) => {
                //     return {
                //         label: `${code.reportName}`,
                //         value: code.reportingTypeLkpcd,
                //         periodBeginDate: code.periodBeginDate,
                //         periodEndDate: code.periodEndDate,
                //     }
                // })

                // setInstaceDropdownRecords(dropdown);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const fetchGetMcReportData = async () => {
        try {
            const response = await ReportInventoryService.getAllMcReports();
            if (response) {
                const { data } = response;
                const filterAndMapData = () => {
                    return data
                        .map((code: any) => ({
                            reportName: `${code.reportName}`,
                            reportDescription: code.reportDescription,
                        }));
                };
                setMcReportList(filterAndMapData);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getProgramReportByProgramReportSid = async (id: any) => {
        try {
            if (id) {
                const response = await ReportInventoryService.getProgramReportByProgramReportSid(id);

                if (response) {
                    const { data } = response;
                    setMcProgramReportDate(data);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getCheckListItemsByReportId = async (id: any) => {
        try {
            if (!id) {
                const response = await ReportInventoryService.getCheckListItemByCheckListTypeId(AdhocChecklistId.CHECKLIST_ID_21, CheckList_Type_Lkpcd.MSBA)
                if (response) {
                    const { data } = response;
                    setChecklistItems(data);
                }
            }
            else {
                if (id) {
                    const response = await ReportInventoryService.getCheckListItemsByProgramReportSid(id, CheckList_Type_Lkpcd.MSBL);
                    if (response) {
                        const { data } = response;
                        setChecklistItems(data);
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    const fetchLatestAvtivity = async (id: any) => {
        try {
            const activitysResponse = await ReportInventoryService.getLatestActivityByInstanceId(id);

            if (activitysResponse) {
                const activity = activitysResponse.data;
                if (activity.submitFlag === "N") {
                    set_V_DB_FLAG('U');
                    setLatestActivityData(activity);

                    const attachmentResponse = await ReportInventoryService.getLAttachmentByActivityId(activity.mctReportActivitySid);

                    if (attachmentResponse) {
                        const existingFiles = attachmentResponse.data.map((file: any) => ({
                            id: file.mctReportAttachmentSid,
                            fileName: file.fileName,
                            uploadTypeLkpcd: file.uploadTypeLkpcd,
                            uploaded: true,
                            file: file.fileName,
                        }));
                        setFileValue(existingFiles);

                    }

                    const mctReportChecklistresponse = await ReportInventoryService.getReportChecklistByActivityId(activity.mctReportActivitySid);

                    if (mctReportChecklistresponse.status === 200) {
                        const savedChecklistItemIds = mctReportChecklistresponse.data.map((item: any) => item.checklistItemId);
                        setSelectedChecklistItemIds(savedChecklistItemIds);
                    }
                } else {
                    set_V_DB_FLAG('I');
                    setLatestActivityData(activity);
                }
            }

        } catch (e) {
            console.error(e);
        }
    };

    const fetchUploadedFilesList = async (id: any) => {
        try {
            setIsLoading(true);
            const attachmentsResponse = await ReportInventoryService.mctReportAttachments(id);
            const blobFilesResponse = await ReportInventoryService.getUploadedFilesListBySid('mcofiles', 'NEMCIS-' + id);

            if (attachmentsResponse && blobFilesResponse) {
                const attachments = attachmentsResponse.data;
                const blobFiles = blobFilesResponse.data;

                const combinedResponse = attachments.map((attachment: any) => {
                    const matchingBlobFile = blobFiles.find((blobFile: any) => parseInt(blobFile.attachmentRecordId) === attachment.mctReportAttachmentSid);
                    return {
                        ...attachment,
                        ...matchingBlobFile,
                        file: matchingBlobFile ? matchingBlobFile.blobName : attachment.fileName
                    };
                });

                setUploadedFileList(combinedResponse);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLatestAvtivityWithoutAttachment = async (id: any) => {
        try {
            const activitysResponse = await ReportInventoryService.getLatestActivityByInstanceId(id);

            if (activitysResponse) {
                const activity = activitysResponse.data;

                if (activity.submitFlag === "N") {
                    set_V_DB_FLAG('U');
                    setLatestActivityData(activity);
                }
                else if (activity.submitFlag === "Y") {
                    set_V_DB_FLAG('I');
                    setLatestActivityData(activity);
                }
                return activity;
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([
                    fetchGetMasterTableLookupData(),
                    fetchGetMasterTableLookupValueData(),
                    // getAllInstaceRecordsForUpload(),
                    fetchGetMcReportData(),
                ]);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (V_DB_FLAG === 'U') {
            setSubmissionNumber(latestActivityData?.submissionNumber);
            setReviewNumber(latestActivityData?.reviewNumber);
            setUploadReportNotes(latestActivityData?.activityNotes);
        }
    }, [V_DB_FLAG]);

    interface FileWithMetadata {
        id: number;
        fileName: string;
        uploadTypeLkpcd: string;
        uploaded: boolean;
        file: File | String;
        blobName?: string;
    }

    const fileInputRef = useRef<HTMLInputElement>(null);
    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    function validateForm(): void {

        if (
            !uploadNewReport.mcoName ||
            !uploadNewReport.reportName ||
            !uploadNewReport.reportType ||
            !uploadNewReport.submissionModeLkpcd
        ) {
            setIsFormValid(false)
        } else {
            setIsFormValid(true)
        }
    }

    useEffect(() => {
        validateForm()
    }, [uploadNewReport])

    const formatDateForInput = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('YYYY-MM-DD') : '';
        }
        return '';
    };

    const formatDateAndTime = (value: any) => {
        if (value) {
            const date = moment(value);
            return date.isValid() ? date.format('M/D/YY h:mm A') : '';
        }
        return '';
    };

    function handleReportingTypeData(event: any) {
        const { name, value } = event.target;

        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }));

        const filteredRecords = instanceRecords.filter((item: any) => item.reportingTypeLkpcd === value);
        const uniqueNames = Array.from(new Set(filteredRecords.map((item: any) => item.reportName)));

        const dropdownOptions = uniqueNames.map((reportName: string) => ({
            label: reportName,
            value: reportName,
        }));

        setUniqueInstanceNameRecords(dropdownOptions);
        setPeriodDropdownRecords([]);
    }

    function handleReportingNameData(event: any) {
        const { name, value } = event.target;
        setPeriodDropdownRecords([]);
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }));

        const filteredRecords = instanceRecords.filter((item: any) => item.reportName === value &&
            item.reportingTypeLkpcd === uploadNewReport.reportType);

        const periodDropdownOptions = filteredRecords.map((item: any) => ({
            label: `${formatDateForInput(item.periodBeginDate)} - ${formatDateForInput(item.periodEndDate)}`,
            value: item.mctReportInstanceSid,
        }));

        setPeriodDropdownRecords(periodDropdownOptions);
    }

    function handlePeriodData(event: any) {
        const selectedMctReportInstanceSid = event.target.value;
        const selectedPeriodRecord = instanceRecords.find(record => record.mctReportInstanceSid === parseInt(selectedMctReportInstanceSid, 10));

        if (selectedPeriodRecord) {
            setUploadNewReport((prevState: any) => ({
                ...prevState,
                mctReportInstanceSid: selectedPeriodRecord?.mctReportInstanceSid,
                programReportSid: selectedPeriodRecord?.programReportSid,
                referenceNumber: selectedPeriodRecord?.stateReferenceNumber,
                frequency: selectedPeriodRecord?.frequency,
                description: getMatchingMcReportDescription(selectedPeriodRecord?.reportName),
                originalDueDate: formatDateForInput(selectedPeriodRecord?.originalDueDate),
                submissionModeLkpcd: selectedPeriodRecord?.reportSbmsnModeLkpcd,
                // submissionNumber: selectedPeriodRecord?.currentSubmissionNumber,
                submsnStatus: selectedPeriodRecord?.lastSubmsnStatusLkpcd,
                // reviewNumber: selectedPeriodRecord?.reviewNumber || '0',
            }));

            setSubmissionNumber(selectedPeriodRecord?.currentSubmissionNumber + 1 || '0');
            setReviewNumber(selectedPeriodRecord?.reviewNumber + 1 || '0')

            if (selectedPeriodRecord?.programReportSid) {
                setIsLoading(true);
                getProgramReportByProgramReportSid(selectedPeriodRecord?.programReportSid);
                getCheckListItemsByReportId(selectedPeriodRecord?.programReportSid);
                setIsLoading(false);
            }
            if (selectedPeriodRecord?.mctReportInstanceSid) {
                setIsLoading(true);
                fetchLatestAvtivity(selectedPeriodRecord?.mctReportInstanceSid);
                fetchUploadedFilesList(selectedPeriodRecord?.mctReportInstanceSid);
                setIsLoading(false);
            }
        }
    }

    const handleChecklistItemChange = (checklistItemId: number) => {
        setSelectedChecklistItemIds(prevSelected => {
            if (prevSelected.includes(checklistItemId)) {
                // Remove the checklistItemId if it is already selected
                return prevSelected.filter(id => id !== checklistItemId);
            } else {
                // Add the checklistItemId if it is not selected
                return [...prevSelected, checklistItemId];
            }
        });
    };

    const handleRichTextChangeNotes = (value: any) => {
        setUploadReportNotes(value)
    }

    const handleExportToExcel = () => {
        const data: { FileName: string; ErrorId: string; ErrorType: string; SheetName: string; Column: string; RowNum: string; ErrorMessage: string }[] = [];

        fileValidateErrors.forEach((fileError: any) => {
            fileError.errors.forEach((error: any) => {
                data.push({
                    FileName: fileError.fileName,
                    ErrorId: error.errId,
                    ErrorType: error.errType,
                    SheetName: error.sheetName,
                    Column: error.column,
                    RowNum: error.rowNum,
                    ErrorMessage: error.ruleErrorMessage,
                });
            });
        });

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Errors');

        XLSX.writeFile(workbook, 'FileValidationErrors.xlsx');
    };

    const closeFileValidateModal = () => {
        setIsFileValidateModalOpen(false);
        history.goBack()
    };

    useEffect(() => {
        // setSelectedChecklistItemIds(selectedChecklistItemIds)
    }, [selectedChecklistItemIds]);

    function handleInputData(event: any) {
        const { name, value } = event.target
    }

    function handleDateInputChange(event: any) {
        const { name, value } = event.target
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const handleDescriptionRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
    }

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);
    const matchingSubmissionModelLkpcd = (data: any) => getMatchingDescription(submissionModeList, data);
    const matchingUploadedFileTypeLKPCD = (data: any) => getMatchingDescription(uploadingFileTypeLKPCDList, data);

    const getMatchingMcReportDescription = (data: any) => {
        const matchedItem = mcReportList.find((code: any) => code.reportName.includes(data));
        return matchedItem?.reportDescription || '';
    };

    function handleClear() {
        history.goBack()
    }

    const mimeTypeMapping: { [key: string]: string } = {
        'PDF': 'application/pdf',
        'JPEG': 'image/jpeg',
        'PNG': 'image/png',
        'TXT': 'text/plain',
        'XLSX': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'XLS': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    async function handleSave() {
        setLoadingButton(true)
        const formData = new FormData();
        for (const file of fileValue) {
            if (typeof file.file !== 'string') {
                formData.append('files', file.file as File);
            } else {
                // Fetch the actual file blob using the blobName
                try {
                    const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + uploadNewReport.mctReportInstanceSid, file.id);
                    const blob = response.data;
                    const mimeType = mimeTypeMapping[file.uploadTypeLkpcd] || 'application/octet-stream';
                    const existingFile = new File([blob], file.fileName, { type: mimeType });
                    formData.append('files', existingFile);
                } catch (error) {
                    console.error(`Error fetching file blob for ${file.fileName}:`, error);
                    setLoadingButton(false);
                    return;
                }
            }
        }
        if (V_DB_FLAG === 'I') {
            uploadNewReport.updateExistingActivity = false;
        } else if (V_DB_FLAG === 'U') {
            uploadNewReport.updateExistingActivity = true;
        }

        uploadNewReport.checkListItems = selectedChecklistItemIds,
            uploadNewReport.activityNotes = uploadReportNotes || '',
            uploadNewReport.activityId = latestActivityData?.mctReportActivitySid;
        uploadNewReport.uploadTypeLkpcd = fileValue.map(file => file.uploadTypeLkpcd),
            uploadNewReport.createdBy = loggedInUserDetails?.userEmailAddress || 'MCO User',
            uploadNewReport.createdDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        uploadNewReport.updatedBy = loggedInUserDetails?.userEmailAddress || 'MCO User',
            uploadNewReport.updatedDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            uploadNewReport.submissionNumber = submissionNumber,
            uploadNewReport.reviewNumber = reviewNumber;

        const attachmentBlob = new Blob([JSON.stringify(uploadNewReport)], { type: 'application/json' });
        formData.append('saveUploadReport', attachmentBlob);

        ReportInventoryService.saveloadReport(formData)
            .then((response) => {
                if (response.status === 200) {
                    const validationErrors = response.data.filter(
                        (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                    );

                    if (validationErrors.length > 0) {
                        const hasSpecificError = validationErrors.some((fileError: any) =>
                            fileError.errors.some((error: any) =>
                                "File name patterns failed".includes(error.ruleErrorMessage)
                                || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                            )
                        );

                        if (hasSpecificError) {
                            const specificErrorMessage = validationErrors
                                .flatMap((fileError: any) => fileError.errors)
                                .find((error: any) =>
                                    "File name patterns failed".includes(error.ruleErrorMessage)
                                    || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                    || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                )?.ruleErrorMessage;

                            toast.error(specificErrorMessage || "An error occurred.");
                        }
                        else {
                            setFileValidateErrors(validationErrors);
                            setIsFileValidateModalOpen(true);
                        }
                    } else {
                        toast.success('Report saved successfully');
                        history.push('/admin-dashboard');
                    }
                }
                else if (response.status === 413) {
                    toast.error(response.data.error);
                }
            })
            .catch((error) => {
                console.log('error', error);
                toast.error('Failed to save report');
            })
            .finally(() => {
                setLoadingButton(false);
            });
    }

    async function handleSubmit() {
        setLoadingButton(true)
        const formData = new FormData();
        for (const file of fileValue) {
            if (typeof file.file !== 'string') {
                formData.append('files', file.file as File);
            } else {
                // Fetch the actual file blob using the blobName
                try {
                    const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + uploadNewReport.mctReportInstanceSid, file.id);
                    const blob = response.data;
                    const mimeType = mimeTypeMapping[file.uploadTypeLkpcd] || 'application/octet-stream';
                    const existingFile = new File([blob], file.fileName, { type: mimeType });
                    formData.append('files', existingFile);
                } catch (error) {
                    console.error(`Error fetching file blob for ${file.fileName}:`, error);
                    setLoadingButton(false);
                    return;
                }
            }
        }
        if (V_DB_FLAG === 'I') {
            uploadNewReport.updateExistingActivity = false;

            uploadNewReport.checkListItems = selectedChecklistItemIds,
                uploadNewReport.activityNotes = uploadReportNotes || '',
                uploadNewReport.activityId = latestActivityData?.mctReportActivitySid;
            uploadNewReport.uploadTypeLkpcd = fileValue.map(file => file.uploadTypeLkpcd),
                uploadNewReport.createdBy = loggedInUserDetails?.userEmailAddress || 'MCO User',
                uploadNewReport.createdDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
            uploadNewReport.updatedBy = loggedInUserDetails?.userEmailAddress || 'MCO User',
                uploadNewReport.updatedDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
            uploadNewReport.submissionNumber = submissionNumber,
                uploadNewReport.reviewNumber = reviewNumber;

            const attachmentBlob = new Blob([JSON.stringify(uploadNewReport)], { type: 'application/json' });
            formData.append('saveUploadReport', attachmentBlob);

            try {
                setLoadingButton(true)
                const saveResponse = await ReportInventoryService.saveloadReport(formData)
                if (saveResponse.status === 200) {
                    const validationErrors = saveResponse.data.filter(
                        (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                    );

                    if (validationErrors.length > 0) {
                        const hasSpecificError = validationErrors.some((fileError: any) =>
                            fileError.errors.some((error: any) =>
                                "File name patterns failed".includes(error.ruleErrorMessage)
                                || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                            )
                        );

                        if (hasSpecificError) {
                            const specificErrorMessage = validationErrors
                                .flatMap((fileError: any) => fileError.errors)
                                .find((error: any) =>
                                    "File name patterns failed".includes(error.ruleErrorMessage)
                                    || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                    || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                                )?.ruleErrorMessage;

                            toast.error(specificErrorMessage || "An error occurred.");
                        }
                        else {
                            setFileValidateErrors(validationErrors);
                            setIsFileValidateModalOpen(true);
                        }
                    } else {
                        setLatestActivityData(null);
                        const latestActivityData = await fetchLatestAvtivityWithoutAttachment(uploadNewReport?.mctReportInstanceSid);
                        setLatestActivityData(latestActivityData);

                        handleSubmitWithLatestData(latestActivityData);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Failed to save or submit actions');
            } finally {
                setLoadingButton(false);
            }
        } else {
            handleSubmitWithLatestData(latestActivityData);
        }
        setLoadingButton(false)
    }

    const handleSubmitWithLatestData = async (latestActivityData: any) => {
        setLoadingButton(true)
        const formData = new FormData();
        for (const file of fileValue) {
            if (typeof file.file !== 'string') {
                formData.append('files', file.file as File);
            } else {
                // Fetch the actual file blob using the blobName
                try {
                    const response = await ReportInventoryService.downloadFileContentAttchanchmentId('mcofiles', 'NEMCIS-' + uploadNewReport.mctReportInstanceSid, file.id);
                    const blob = response.data;
                    const mimeType = mimeTypeMapping[file.uploadTypeLkpcd] || 'application/octet-stream';
                    const existingFile = new File([blob], file.fileName, { type: mimeType });
                    formData.append('files', existingFile);
                } catch (error) {
                    console.error(`Error fetching file blob for ${file.fileName}:`, error);
                    setLoadingButton(false);
                    return;
                }
            }
        }
        uploadNewReport.checkListItems = selectedChecklistItemIds,
            uploadNewReport.activityNotes = uploadReportNotes || '',
            uploadNewReport.activityId = latestActivityData.mctReportActivitySid;
        uploadNewReport.uploadTypeLkpcd = fileValue.map(file => file.uploadTypeLkpcd),
            uploadNewReport.createdBy = loggedInUserDetails?.userEmailAddress || 'MCO User',
            uploadNewReport.createdDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        uploadNewReport.updatedBy = loggedInUserDetails?.userEmailAddress || 'MCO User',
            uploadNewReport.updatedDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        uploadNewReport.submissionNumber = submissionNumber,
            uploadNewReport.reviewNumber = reviewNumber;

        const attachmentBlob = new Blob([JSON.stringify(uploadNewReport)], { type: 'application/json' });
        formData.append('submitUploadReport', attachmentBlob);

        try {
            const submitResponse = await ReportInventoryService.submitUploadReport(formData)
            if (submitResponse.status === 200) {
                const validationErrors = submitResponse.data.filter(
                    (file: any) => file.validationStatus === "-1" || file.validationStatus === "1" || file.validationStatus === "2" && file.valid === false
                );

                if (validationErrors.length > 0) {
                    const hasSpecificError = validationErrors.some((fileError: any) =>
                        fileError.errors.some((error: any) =>
                            "File name patterns failed".includes(error.ruleErrorMessage)
                            || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                            || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                        )
                    );

                    if (hasSpecificError) {
                        const specificErrorMessage = validationErrors
                            .flatMap((fileError: any) => fileError.errors)
                            .find((error: any) =>
                                "File name patterns failed".includes(error.ruleErrorMessage)
                                || "Last activity's submit status is not 'Y'".includes(error.ruleErrorMessage)
                                || error.ruleErrorMessage.includes("File size exceeds maximum limit 10MB")
                            )?.ruleErrorMessage;

                        toast.error(specificErrorMessage || "An error occurred.");
                    } else {
                        setFileValidateErrors(validationErrors);
                        setIsFileValidateModalOpen(true);
                    }
                } else {
                    toast.success('Report submitted successfully');
                    history.push('/admin-dashboard');
                }
            }
        }
        catch (error) {
            console.error('Error:', error);
            toast.error('Failed to save or submit actions');
        } finally {
            setLoadingButton(false);
        }
    };

    const handleRemoveFile = (fileId: string) => {
        setFileValue(prevFiles => prevFiles.filter(f => f.id !== Number(fileId)));
    };

    const openRemoveFileModal = (fileId: any) => {
        setFileIdToDelete(fileId);
        setRemoveFileModalOpen(true);
        setRemovingFileName(fileValue.find(f => f.id === Number(fileId))?.fileName || '');
    };

    const closeRemoveFileModal = () => {
        setRemoveFileModalOpen(false);
        setFileIdToDelete(null);
        setRemovingFileName('')
    };

    const updatedUploadedFileStatus = async (attachmentRecordId: any) => {
        try {
            const request = {
                attachmentRecordId: attachmentRecordId,
                updatedBy: accounts[0]?.username || '',
                updatedDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
            };
            const response = await ReportInventoryService.updatedUploadedFileStatus(request);
            if (response.status === 200) {
                toast.success('File status updated successfully');
            }
        } catch (e) {
            console.error(e);
            toast.error('Error while updating file status');
        }
    }
    const confirmDelete = () => {
        if (fileIdToDelete !== null) {
            console.log('fileIdToDelete', fileIdToDelete);
            uploadedFileList.forEach((file: any) => {
                if (file.attachmentRecordId === fileIdToDelete) {
                    updatedUploadedFileStatus(fileIdToDelete)
                }
            })

            handleRemoveFile(fileIdToDelete);
        } else {
            if (fileIdToDelete !== null) {
                handleRemoveFile(fileIdToDelete);
            }
        }

        closeRemoveFileModal();
    };

    const handleFileUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map(file => ({
                id: Date.now(),
                fileName: file.name,
                uploadTypeLkpcd: '',
                uploaded: true,
                file: file // Store the actual file object
            }));
            setFileValue(prevFiles => {
                // Merge new files with existing files, avoiding duplicates
                const existingFiles = prevFiles.filter(file => !newFiles.some(newFile => newFile.fileName === file.fileName));
                return [...existingFiles, ...newFiles];
            });
        }
    };

    const handleFileTypeChange = (e: any, fileId: any) => {
        const { value } = e.target;
        const isRFSelected = value === 'RF';
        const isCSSelected = value === 'CS';
        const isAFSelected = value === 'AF';
        const isRFAlreadySelected = fileValue.some((file) => file.uploadTypeLkpcd === 'RF' && file.id !== fileId);
        const isCurrentFileRF = fileValue.find((file) => file.id === fileId)?.uploadTypeLkpcd === 'RF';
        const isLastRF = fileValue.filter((file) => file.uploadTypeLkpcd === 'RF').length === 1;
        const afCount = fileValue.filter((file) => file.uploadTypeLkpcd === 'AF').length;

        // Check if RF is already selected for another file
        if (isRFSelected && isRFAlreadySelected) {
            toast.warn('One Report File already selected.');
            return;
        }

        else {
            // Check if the current file is the last RF and the user is trying to deselect RF
            if (!isRFSelected && isCurrentFileRF && isLastRF) {
                toast.warn('Must have the Report File');
                return;
            }

            // Check if no file has RF selected and the user is trying to select another type
            if (!isRFSelected && !isRFAlreadySelected && !isCurrentFileRF) {
                toast.warn('Must have the Report File');
                return;
            }
            if (mcProgramReportDate) {
                // Check if Certification Statement File is selected and mcProgramReportDate.certificationReqdFlag is not Y
                if (isCSSelected && mcProgramReportDate.certificationReqdFlag !== 'Y') {
                    toast.warn('Certification Statement File type not allow.');
                    return;
                }

                // Check if Associated File is selected and mcProgramReportDate.associatedFilesCount is reached
                if (isAFSelected && afCount >= mcProgramReportDate.associatedFilesCount) {
                    toast.warn('Associated File not allowed.');
                    return;
                }
            }
        }


        const updatedFiles = fileValue.map((file) =>
            file.id === fileId ? { ...file, uploadTypeLkpcd: value || file.uploadTypeLkpcd } : file
        );
        setFileValue(updatedFiles);
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Upload- Submit Reports" />
                </div>
            </div>
            <div className="form-layout">

                <div className="details-block">
                    <div className="row1">
                        <div className="element" >
                            <Dropdown
                                choices={reporPlanOptions}
                                emptyChoiceLabel="Select MCO Plan"
                                name="mcoName"
                                label="MCO Name"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.mcoName || ''}
                                onChange={handleInputData}
                                mandatoryLabel={true}
                                disabled={true}
                            />
                        </div>
                        <div className="element">
                            <Dropdown
                                choices={reportTypeList}
                                emptyChoiceLabel="Select Reporting Type"
                                name="reportType"
                                label="Reporting Type"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.reportType || ''}
                                onChange={handleReportingTypeData}
                                mandatoryLabel={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <Dropdown
                                choices={uniqueInstanceNameRecords}
                                emptyChoiceLabel="Select Report Name"
                                name="reportName"
                                label="Report Name"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.reportName || ''}
                                onChange={handleReportingNameData}
                                mandatoryLabel={true}
                            />

                        </div>
                        <div className="element" >
                            <Dropdown
                                choices={periodDropdownRecords}
                                emptyChoiceLabel="Select period"
                                name="period"
                                label="Period"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.period}
                                onChange={handlePeriodData}
                                mandatoryLabel={true}
                            />

                        </div>
                    </div>
                    <div className="row1">
                        <div className="element">
                            <div className="usa-form-group">
                                <InputText
                                    context="registration"
                                    label="Frequency"
                                    name="frequency"
                                    value={uploadNewReport.frequency || ''}
                                    onChange={handleInputData}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="element">
                            <InputText
                                context="registration"
                                label="Reference #"
                                name="referenceNumber"
                                placeholder="Reference Name"
                                value={uploadNewReport.referenceNumber || ''}
                                onChange={handleInputData}
                                disabled={true}
                            />

                        </div>
                    </div>
                    <div className="row1">
                        <div className="element">
                            <div className="usa-form-group">
                                <label htmlFor="my-editor">
                                    <b>Description:</b>
                                </label>
                                <ReactQuill
                                    className="my-editor greyed-out"
                                    style={{ maxHeight: '150px', overflowY: 'auto' }}
                                    value={uploadNewReport.description}
                                    onChange={handleDescriptionRichTextChangeAppDetails}
                                    readOnly={true}
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                            [{ align: [] }], // alignment options
                                            ['clean'], // remove formatting option
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                        <div className="element">
                            <div className="usa-form-group">
                                <FormInput
                                    label="Due Date"
                                    type="date"
                                    name="originalDueDate"
                                    value={uploadNewReport.originalDueDate}
                                    onChange={handleDateInputChange}
                                    placeholder={'MM-DD-YYYY'}
                                    id={''}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element">
                            <InputText
                                context="registration"
                                label="Submission Channel"
                                name="submissionModeLkpcd"
                                value={matchingSubmissionModelLkpcd(uploadNewReport.submissionModeLkpcd)}
                                onChange={handleInputData}
                                disabled={true}
                            />
                        </div>

                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Submission #"
                                name="submissionNumber"
                                placeholder="Submission Number"
                                value={uploadNewReport.submissionNumber || ''}
                                onChange={handleInputData}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="row1">
                        <div className="element">
                            <InputText
                                context="registration"
                                label="Submission Status"
                                name="submsnStatus"
                                placeholder="Submission Status"
                                value={matchingBusinessStatusLkpcd(uploadNewReport.submsnStatus)}
                                onChange={handleInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {isLoading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <p>Loading.....</p>
                        </div>
                    ) :
                        <>
                            <div className="details-block">
                                <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                    <label style={{ marginBottom: '10px' }}>
                                        <b>Add Notes for the State, if any:</b>
                                    </label>
                                    <ReactQuill
                                        style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                                        value={uploadReportNotes}
                                        onChange={handleRichTextChangeNotes}
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline', 'strike'], // formatting options
                                                [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                                [{ align: [] }], // alignment options
                                                ['clean'], // remove formatting option
                                            ],
                                        }}
                                    />
                                </div>

                                <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                    <label style={{ marginBottom: '10px' }}>
                                        <b>Attachments, if any required:</b>
                                    </label>

                                    <div className="element">
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: '#d3d3d3' }}>
                                                        <th style={{ border: '1px solid black' }}>No</th>
                                                        <th style={{ border: '1px solid black' }}>File Name</th>
                                                        <th style={{ border: '1px solid black' }}>File Type</th>
                                                        <th style={{ border: '1px solid black' }}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ alignContent: 'center' }}>
                                                    <tr>
                                                        <td colSpan={4} style={{ border: '1px solid black', textAlign: 'center' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5px', marginTop: '-5px' }}>
                                                                <Button
                                                                    className="choose-file-button"
                                                                    // disabled={!checkAccess('mco_actions_page', 'FULL')}
                                                                    onClick={handleFileUploadButtonClick}                                                                >
                                                                    Add an Attachment
                                                                </Button>
                                                                <input
                                                                    type="file"
                                                                    ref={fileInputRef}
                                                                    style={{ display: 'none' }}
                                                                    onChange={handleFileChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    {fileValue.length > 0 ? (
                                                        fileValue.map((file, index) => (
                                                            <tr key={index}>
                                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>{index + 1}</td>
                                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.fileName}</td>
                                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                    {file.uploaded ? (
                                                                        <div>
                                                                            <select
                                                                                value={file.uploadTypeLkpcd || ""}
                                                                                onChange={(e) => handleFileTypeChange(e, file.id)}
                                                                            >
                                                                                <option value="">Select File Type</option>
                                                                                {uploadingFileTypeLKPCDList.map((item: any) => (
                                                                                    <option key={item.lkpdmn_lkpcd} value={item.lkpdmn_lkpcd}>
                                                                                        {item.lkdmn_lkpcd_desc}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            <span className="usa-mandatory-label" style={{ marginLeft: '5px', color: 'red' }}>*</span>
                                                                        </div>
                                                                    ) : (
                                                                        matchingUploadedFileTypeLKPCD(file.uploadTypeLkpcd)
                                                                    )}
                                                                </td>
                                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                    {file.fileName && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => openRemoveFileModal(file.id.toString())}
                                                                            style={{ border: '1px solid red', borderRadius: '5px', padding: '5px' }}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={4} style={{ border: '1px solid black', textAlign: 'center', fontSize: '14px' }}>No files available</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <Modal
                        isOpen={isRemoveFileModalOpen}
                        onRequestClose={closeRemoveFileModal}
                        contentLabel="Confirm Remove"
                        style={{
                            content: {
                                width: '500px',
                                height: '200px',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                border: '1px solid red',
                            },
                        }}
                    >
                        <h2><b style={{ fontSize: '20px' }}>Confirm Remove</b></h2>
                        <p style={{ marginTop: '10px', fontSize: '18px', }}>Are you sure you want to delete this file?</p>
                        <p style={{ marginTop: '10px' }}>{removingFileName}</p>
                        <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                            <button
                                className='usa-button'
                                onClick={confirmDelete}
                            >
                                Yes
                            </button>
                            <button
                                className='usa-button'
                                onClick={closeRemoveFileModal}
                            >
                                No
                            </button>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isFileValidateModalOpen}
                        onRequestClose={closeFileValidateModal}
                        contentLabel="File Validation Errors"
                        style={{
                            content: {
                                width: '80%',
                                height: '80%',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                border: '1px solid red',
                                overflow: 'hidden',
                            },
                        }}
                    >
                        <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                            <h2><b style={{ fontSize: '20px' }}>File validation errors</b></h2>
                            <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%', marginBottom: '10px' }}>
                                <button
                                    disabled={fileValidateErrors.length === 0}
                                    className='usa-button'
                                    onClick={handleExportToExcel}
                                >
                                    Download Excel
                                </button>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#d3d3d3' }}>
                                            <th style={{ border: '1px solid black' }}>Error Id</th>
                                            <th style={{ border: '1px solid black' }}>Error Type</th>
                                            <th style={{ border: '1px solid black' }}>Sheet Name</th>
                                            <th style={{ border: '1px solid black' }}>Column</th>
                                            <th style={{ border: '1px solid black' }}>Row</th>
                                            <th style={{ border: '1px solid black' }}>Error Message</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignContent: 'center' }}>
                                        {fileValidateErrors.length > 0 ? (
                                            fileValidateErrors.map((fileError: any, fileIndex: any) => (
                                                <React.Fragment key={fileIndex}>
                                                    <tr>
                                                        <td colSpan={6} style={{ border: '1px solid black', fontSize: '14px', fontWeight: 'bold' }}>
                                                            {`File Name: ${fileError.fileName}`}
                                                        </td>
                                                    </tr>
                                                    {fileError.errors.map((error: any, errIndex: any) => (
                                                        <tr key={errIndex}>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.errId}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.errType}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.sheetName}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.column}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.rowNum}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{error.ruleErrorMessage}</td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6} style={{ border: '1px solid black', fontSize: '14px', textAlign: 'center' }}>
                                                    No validation errors found.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                            <button
                                className='usa-button'
                                onClick={closeFileValidateModal}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                    <div className="element">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#d3d3d3' }}>
                                        <th style={{ border: '1px solid black' }}>Select</th>
                                        <th style={{ border: '1px solid black' }}>Checklist Item</th>
                                    </tr>
                                </thead>
                                <tbody style={{ alignContent: 'center' }}>
                                    {checklistItems.map((item: any) => (
                                        <tr key={item.checklistItemId}>
                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    type="checkbox"
                                                    style={{ margin: '5px', transform: 'scale(1.5)' }}
                                                    checked={selectedChecklistItemIds.includes(item.checklistItemId)}
                                                    onChange={() => handleChecklistItemChange(item.checklistItemId)}
                                                />
                                            </td>
                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{item.itemDescription}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row md:justify-end w-full" style={{ marginBottom: '-20px' }}>
                    <Button
                        disabled={(fileValue.length > 0 && (fileValue.some(file => !file.uploadTypeLkpcd))) || !isFormValid}
                        className="OkButton mb-4 md:mb-0 md:ml-4"
                        onClick={handleSave}
                        loading={loadingButton}
                    >
                        Save
                    </Button>
                    <Button
                        disabled={(fileValue.length > 0 && (fileValue.some(file => !file.uploadTypeLkpcd))) || !isFormValid}
                        className="OkButton mb-4 md:mb-0 md:ml-4"
                        onClick={handleSubmit}
                        loading={loadingButton}
                    >
                        Submit
                    </Button>
                    <Button
                        className="OkButton md:ml-4"
                        onClick={() => handleClear()}
                    >
                        Cancel
                    </Button>
                </div>
            </div >
            <Footer />
        </>
    );

}
export default UploadReportInventory;