import React, { useRef, useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './upload-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import MultiSelectDropdown from '../../component/Common/CheckboxDropdown'
import ReactQuill from 'react-quill'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import { includes } from 'lodash';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useAccessControl } from '../../../src/utils/AccessControlContext';

const AdhocOrNewOnDemandReport = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const ReportInventoryService = useReportInventoryService();
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const [isLoading, setIsLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState<boolean>(false)
    const [fileName, setFileName] = useState('');
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedMCONameDropdown, setSelectedMCONameDropdown] = useState('');
    const [selectGroup, setSelectGroup] = useState('');
    const [uploadNewReport, setUploadNewReport] = useState<any>({
        planId: '',
        programReportSid: '',
        reportName: '',
        stateReferenceNumber: '',
        reportSbmsnModeLkpcd: '',
        reportType: '',
        periodBeginDate: '',
        periodEndDate: '',
        latestDueDate: '',
        notesSummary: '',
        // lastSubmsnStatusLkpcd: '10', // default value 10(Scheduled) 
        currentGroup: '',
        // currentSubmissionNumber: '0',
        lastValidationStatusLkpcd: 'A',
        lastReviewStatusLkpcd: '',
        oprtnlFlag: 'A',
        lastSubmsnDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        originalDueDate: '',
        createdBy: accounts[0]?.username || loggedInUserDetails.userEmailAddress || 'MCO User',
        createdDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        updatedBy: accounts[0]?.username || loggedInUserDetails.userEmailAddress || 'MCO User',
        updatedDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        activityStartDateTime: '',
        activityEndDateTime: '',
    });
    const [reportNameOptions, setReportNameOptions] = useState<any[]>([]);
    const [reporPlanOptions, setReportPlanOptions] = useState<any[]>([]);
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [submissionModeList, setSubmissionModeList] = useState<any>([])
    const [currentDueDate, setCurrentDueDate] = useState<string>('');
    const [periodEndDate, setPeriodEndDate] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [listOfExistingReports, setListOfExistingReports] = useState<string[]>([]);
    const [isReportNameExists, setIsReportNameExists] = useState(false);
    const { getMasterTableLookupValueData, getMasterTableLookupData } = useLooksUpData();
    const { checkAccess } = useAccessControl();
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";

    const userType = sessionStorage.getItem('signedInUserType');
    const fetchGetMasterTableLookupData = async () => {
        try {
            getMasterTableLookupData().then((response: any) => {
                if (response) {

                    let filteredMcPlan;
                    if (loggedInUserDetails.organizationUnitCode === "NEDHHS") {
                        filteredMcPlan = response.data.mc_plan;
                    } else {
                        filteredMcPlan = response.data.mc_plan.filter((item: { organizationUnitCode: string }) =>
                            item.organizationUnitCode === loggedInUserDetails.organizationUnitCode
                        );
                    }

                    const mcPlanOptions = filteredMcPlan.map((item: { planId: string, planName: string }) => ({
                        value: item.planId,
                        label: item.planName,
                    }));

                    setUploadNewReport((prevState: any) => {
                        return {
                            ...prevState,
                            planId: [mcPlanOptions[0].value]
                        };
                    });

                    const mcReportName = response.data.mc_program_reports.map((dt: any) => dt.reportName)
                    setListOfExistingReports(mcReportName);

                    const mcReportingTypeLkpcd = response.data.mc_program_reports.filter(
                        (dt: any) => dt.reportingTypeLkpcd === 'D'
                    );

                    const mcProgramReportOptions = mcReportingTypeLkpcd.map((item: { programReportSid: string, reportName: string, stateReferenceNumber: string, reportingTypeLkpcd: string }) => ({
                        value: item.programReportSid,
                        label: item.reportName,
                        stateReferenceNumber: item.stateReferenceNumber,
                        reportingTypeLkpcd: item.reportingTypeLkpcd
                    }));

                    setReportPlanOptions(mcPlanOptions);
                    setReportNameOptions(mcProgramReportOptions);
                }

            })

        } catch (e) {
            console.log(e);
        }
    }

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            getMasterTableLookupValueData().then((response: any) => {
                if (response) {

                    const mcReportType = response.data.filter(
                        (dt: any) => dt.column_name.toLowerCase() === 'reporting_type_lkpcd' && dt.lkpcd_group === 'AD'
                    )

                    const mcSubmisstionMode = response.data.filter(
                        (dt: any) => dt.lkpcd_group === 'AD' && dt.table_name === 'MC_PROGRAM_REPORT' && dt.column_name === 'REPORT_SBMSN_MODE_LKPCD'
                    )

                    const dropdown = mcReportType.map((code: any) => {
                        return {
                            label: `${code.lkdmn_lkpcd_desc}`,
                            value: code.lkpdmn_lkpcd,
                            group: code.lkpcd_group
                        }
                    })

                    const mcSubMode = mcSubmisstionMode.map((code: any) => {
                        return {
                            value: `${code.lkpdmn_lkpcd}`,
                            label: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }
                    })

                    setReportTypeList(dropdown);
                    setSubmissionModeList(mcSubMode);
                }
            })

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        uploadNewReport.activityStartDateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                fetchGetMasterTableLookupData(),
                fetchGetMasterTableLookupValueData()
            ]);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (isNonStateUser) {

                const selectedReportTypeObj = reportTypeList.find((code: any) => uploadNewReport.reportType.includes(code.value));
                let newGroup = '';
                if (selectedReportTypeObj) {
                    newGroup = selectedReportTypeObj.group;
                    setSelectGroup(newGroup)
                }

                setUploadNewReport((prevState: any) => {
                    return {
                        ...prevState,
                        reportType: 'D',
                    };
                })
            }
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [userType, reportTypeList, reportNameOptions, uploadNewReport.reportType]);

    useEffect(() => {
        if (uploadNewReport.programReportSid !== null) {
            const selectedReportNmeObj = reportNameOptions.find((code: any) => uploadNewReport.programReportSid === code.value);
            let selectedReportName = selectedReportNmeObj?.label;
            uploadNewReport.reportName = selectedReportName;
        } else {
            uploadNewReport.reportName = uploadNewReport.reportName;
        }
    }, [uploadNewReport.programReportSid])

    function validateForm(): void {
        if (loggedInUserDetails.organizationUnitCode === "NEDHHS") {
            if (uploadNewReport.reportType === 'D') {
                if (
                    !uploadNewReport.reportType ||
                    !uploadNewReport.planId ||
                    !uploadNewReport.programReportSid ||
                    !uploadNewReport.latestDueDate ||
                    !uploadNewReport.periodBeginDate ||
                    !uploadNewReport.periodEndDate ||
                    !uploadNewReport.reportSbmsnModeLkpcd
                ) {
                    setIsFormValid(false)
                } else {
                    setIsFormValid(true)
                }

            } else {
                if (
                    !uploadNewReport.reportType ||
                    !uploadNewReport.planId ||
                    !uploadNewReport.reportName ||
                    !uploadNewReport.latestDueDate ||
                    !uploadNewReport.periodBeginDate ||
                    !uploadNewReport.periodEndDate ||
                    !uploadNewReport.reportSbmsnModeLkpcd
                ) {
                    setIsFormValid(false)
                } else {
                    setIsFormValid(true)
                }
            }
        }
        else if (
            !selectedValues ||
            !uploadNewReport.reportType ||
            !uploadNewReport.reportName ||
            !uploadNewReport.latestDueDate ||
            !uploadNewReport.periodBeginDate ||
            !uploadNewReport.periodEndDate ||
            !uploadNewReport.reportSbmsnModeLkpcd
        ) {
            setIsFormValid(false)
        } else {
            setIsFormValid(true)
        }
    }

    useEffect(() => {
        validateForm()
    }, [uploadNewReport])

    function validateReportName(): boolean {
        const reportNameExists = listOfExistingReports.includes(uploadNewReport.reportName);
        setIsReportNameExists(reportNameExists);
        return !reportNameExists;
    }

    useEffect(() => {
        if (uploadNewReport.reportType === 'A') {
            validateReportName()
        }
    }, [uploadNewReport.reportName])

    function handleInputData(event: any) {
        const { name, value } = event.target;
        const selectedValue = event.target.value;
        const selectedReportTypeObj = reportTypeList.find((code: any) => selectedValue.includes(code.value));
        let newGroup = '';
        if (selectedReportTypeObj) {
            newGroup = selectedReportTypeObj.group;
            setSelectGroup(newGroup)
        }
        if (isNonStateUser) {
            if (value === 'D') {
                setUploadNewReport((prevState: any) => {
                    const newProgramReportSid = 7;
                    const matchingReport = reportNameOptions.find((code: any) => code.value === newProgramReportSid);
                    return {
                        ...prevState,
                        programReportSid: newProgramReportSid,
                        [name]: value,
                        stateReferenceNumber: matchingReport ? matchingReport.stateReferenceNumber : '',
                        reportSbmsnModeLkpcd: 'W'
                    };
                });
            } else {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    programReportSid: '',
                    stateReferenceNumber: '',
                    reportName: '',
                    reportSbmsnModeLkpcd: 'W',
                    [name]: value
                }));
            }
        } else {
            setUploadNewReport((prevState: any) => ({
                ...prevState,
                [name]: value
            }));
        }

        // if (name === 'reportName') {
        //     validateReportName(value);
        // }
    }

    function handleReportNameDropdownData(event: any) {
        const { name, value } = event.target;
        const matchingReport = reportNameOptions.find((code: any) => code.value === parseInt(value));

        setUploadNewReport((prevState: any) => {
            return {
                ...prevState,
                reportType: 'D',
                programReportSid: value,
                reportSbmsnModeLkpcd: 'W',
                stateReferenceNumber: matchingReport ? matchingReport.stateReferenceNumber : ''
            };
        });
    }

    function handleDateInputChange(event: any) {
        const { name, value } = event.target
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const handleDueDateChange = (event: any) => {
        const { name, value } = event.target
        setCurrentDueDate(value);

        // Validate if currentDueDate is before periodEndDate
        if (moment(value).isAfter(moment(periodEndDate))) {
            setErrorMessage('Current Due Date must be before Reporting End Date.');
        } else {
            setErrorMessage('');
        }
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    };

    const handlePeriodEndDateChange = (event: any) => {
        const { name, value } = event.target
        setPeriodEndDate(value);

        // Validate if currentDueDate is before periodEndDate
        if (moment(currentDueDate).isAfter(moment(value))) {
            setErrorMessage('Current Due Date must be before Reporting End Date.');
        } else {
            setErrorMessage('');
        }
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))

    };

    const handleRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
        setUploadNewReport((prevState: any) => ({ ...prevState, ['notesSummary']: appDetails }))
    }

    function handleClear() {
        history.goBack()
    }

    async function handleSave() {
        setLoadingButton(true);
        uploadNewReport.activityEndDateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')

        if (loggedInUserDetails.organizationUnitCode === "NEDHHS") {
            const newProgramReportSid = 7;
            const matchingReport = reportNameOptions.find((code: any) => code.value === newProgramReportSid);
            const selectedReportTypeObj = reportTypeList.find((code: any) => uploadNewReport.reportType.includes(code.value));
            uploadNewReport.currentGroup = selectedReportTypeObj.group;
            uploadNewReport.period = `${moment(uploadNewReport.periodBeginDate).format('MM/DD/YYYY')} - ${moment(uploadNewReport.periodEndDate).format('MM/DD/YYYY')}`;
            // uploadNewReport.planId = ['7600'],
            uploadNewReport.reportType = 'D',
                uploadNewReport.programReportSid = newProgramReportSid,
                uploadNewReport.stateReferenceNumber = matchingReport ? matchingReport.stateReferenceNumber : '',
                uploadNewReport.reportSbmsnModeLkpcd = 'W'
            uploadNewReport.reportName = matchingReport ? matchingReport.label : '';

            try {
                const response = await ReportInventoryService.saveMctreportinstance(uploadNewReport);
                if (response) {
                    console.log('New Report data has been successfully uploaded');
                    toast.success('New Report data has been successfully uploaded');
                    setTimeout(() => {
                        setLoadingButton(true);
                        history.push('/admin-dashboard');
                    }, 500);
                }
            } catch (e) {
                console.error('Error:', e);
                toast.error('Error creating report');
            } finally {
                setLoadingButton(false);
            }
        } else {
            try {
                uploadNewReport.period = `${moment(uploadNewReport.periodBeginDate).format('MM/DD/YYYY')} - ${moment(uploadNewReport.periodEndDate).format('MM/DD/YYYY')}`;
                uploadNewReport.currentGroup = selectGroup;
                const newReport = { ...uploadNewReport, planId: selectedValues };
                const response = await ReportInventoryService.saveMctreportinstance(newReport);
                const allSuccessful = response.status === 200;

                if (allSuccessful) {
                    toast.success('All reports created successfully')
                    setTimeout(() => {
                        history.push('/admin-dashboard');
                    }, 500);
                } else {
                    alert('Some reports failed to create');
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Error creating reports');
            } finally {
                setLoadingButton(false);
            }
        }
    }

    const handleMultiDropdownChange = (selectedOptions: string[]) => {
        setSelectedValues(selectedOptions)
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text={"Add Report"} />
                </div>
            </div>

            <div className="row1">
                <div className="element">
                    <Dropdown
                        // choices={reportTypeOptions}
                        choices={reportTypeList}
                        emptyChoiceLabel="Select Filter Criteria 1"
                        name="reportType"
                        label="Report Type"
                        data-testid="payment_type_testid"
                        value={uploadNewReport.reportType || ''}
                        onChange={handleInputData}
                        mandatoryLabel={isNonStateUser ? false : true}
                        disabled={isNonStateUser ? true : false}
                    />
                </div>
                <div className="element" >
                    {isNonStateUser ? (
                        <Dropdown
                            choices={reporPlanOptions}
                            emptyChoiceLabel="Select MCO Name"
                            name="planId"
                            label="MCO Name"
                            data-testid="mco-name"
                            value={uploadNewReport.planId || ''}
                            onChange={handleInputData}
                            mandatoryLabel={isNonStateUser ? false : true}
                            disabled={isNonStateUser ? true : false}
                        />
                    ) : (
                        <div className="usa-form-group" >
                            <MultiSelectDropdown
                                options={reporPlanOptions}
                                label="MCO Name"
                                name="planId"
                                onChange={handleMultiDropdownChange}
                                selectedValues={selectedValues}
                                mandatoryLabel={true}
                            />
                        </div>
                    )}

                </div>
            </div>
            <div className="row1">
                <div className="element" >
                    {uploadNewReport.reportType === 'A' ? (
                        <>
                            <InputText
                                context="registration"
                                label="Report Name"
                                name="reportName"
                                placeholder="Report Name"
                                value={uploadNewReport.reportName || ''}
                                onChange={handleInputData}
                                mandatoryLabel={true}
                            />
                            {isReportNameExists && (
                                <div style={{ color: 'red' }}>
                                    <p>Entered report name already exists</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <Dropdown
                            choices={reportNameOptions}
                            emptyChoiceLabel="Select Filter Criteria 1"
                            name="programReportSid"
                            label="Report Name"
                            data-testid="payment_type_testid"
                            value={uploadNewReport.programReportSid || ''}
                            onChange={handleReportNameDropdownData}
                            mandatoryLabel={true}
                        />
                    )}
                </div>
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Due Date"
                            type="date"
                            name="latestDueDate"
                            value={currentDueDate}
                            onChange={handleDueDateChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                </div>
            </div>

            <div className="row1">
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Reporting Start Date"
                            type="date"
                            name="periodBeginDate"
                            value={uploadNewReport.periodBeginDate}
                            onChange={handleDateInputChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                </div>
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Reporting End Date"
                            type="date"
                            name="periodEndDate"
                            value={periodEndDate}
                            onChange={handlePeriodEndDateChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                </div>
            </div>
            <div className="row1">
                <div className="element" >
                    <InputText
                        context="registration"
                        label="Reference Number"
                        name="stateReferenceNumber"
                        placeholder="Reference Number"
                        value={uploadNewReport.stateReferenceNumber}
                        onChange={handleInputData}
                        // mandatoryLabel={true}
                        disabled={uploadNewReport.reportType === 'D' ? true : false}
                    // small={true}
                    />
                </div>

                <div className="element">
                    <Dropdown
                        choices={submissionModeList}
                        emptyChoiceLabel="Select Channel"
                        name="reportSbmsnModeLkpcd"
                        label="Channel"
                        data-testid="payment_type_testid"
                        value={uploadNewReport.reportSbmsnModeLkpcd || ''}
                        onChange={handleInputData}
                        mandatoryLabel={true}
                    />
                </div>
            </div>

            <div className="row1" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', marginTop: '20px' }}>
                <label >
                    <b>User Comments:</b>
                </label>
                <ReactQuill
                    style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                    value={uploadNewReport.notesSummary}
                    onChange={handleRichTextChangeAppDetails}
                    modules={{
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                            [{ align: [] }], // alignment options
                            ['clean'], // remove formatting option
                        ],
                    }}
                />
            </div>
            <div className="row1">
                <div className="element" />
                <div className="external-filters-action flex justify-end w-full lg:w-auto lg:max-w-xs">
                    <div>
                        <Button
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!isFormValid || !!errorMessage || isReportNameExists ||
                                checkAccess('add_inventory_report', 'NOAC') || checkAccess('add_inventory_report', 'VWNA')
                            }
                            className="OkButton"
                            onClick={handleSave}
                            loading={loadingButton}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}
export default AdhocOrNewOnDemandReport;