import React from 'react'

const Index = () => {
  return (
    <footer className="py-3  relative z-50 w-full text-center">
      <div className=" mb-4 px-5  text-sm">
                          This is a government computer system.
   <br/>
        Unauthorized access, use, misuse, or modification of this computer
        system or of the data contained herein or in transit to/from this system
        constitutes a violation of Title 18, United States Code, Section 1030,
        and may subject the individual to Criminal and Civil penalties pursuant
        to Title 26, United States Code, Sections 7213 (A), 7213A (the Taxpayer
        Browsing Protection Act), 7431 and Health Insurance Portability and
        Accountability Act of 1996. If monitoring reveals possible evidence of
        criminal activity, such evidence may be provided to Law Enforcement
        Personnel.
      </div>
      <div className="footer">Copyright &copy; DHHS 2024</div>
    </footer>
  )
}

export default Index
