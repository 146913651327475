import './adminDashboard.css'
import React, { useContext, useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import arrow from '../../img/arrow.png'
import ApplicationIcon from '../../img/applications_icon.png'
import { Link, Route } from 'react-router-dom'
import ApiIcon from '../../img/api.jpg'
import TrackIcon from '../../img/track_status.png'
import ManageDevIcon from '../../img/manage_dev.png'
import AssessmentIcon from '../../img/admin_support.png';
import { useMsal } from '@azure/msal-react'
import AssignmentIcon from '@mui/icons-material/Assignment';
import Box from '@mui/material/Box'
import FormInput from '../../component/Common/FormInput'
import Button from '../../component/Common/Button'
import { useHistory } from 'react-router-dom'
import BackButton from '../../component/Common/BackBtn'
import { Typography } from '@mui/material'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import './adminDashboard.css'
import LockIcon from '../../assets/images/lockIcon.png'
import AdminIcon from '../../assets/images/admin.svg'
import reports from '../../assets/images/reports.svg'
import uploadIcon from '../../img/material-icons/file_upload.svg'
import calendarIcon from '../../assets/images/calendar.svg'
import dashboardIcon from '../../img/usa-icons/report.svg'
import referenceIcon from '../../img/usa-icons/folder.svg'
import userManagementIcon from '../../img/manage_dev.png'
import PageSubTitle from '../../component/Common/PageSubTitle'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import Cookies from 'js-cookie';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import Dropdown from "../../component/Common/Dropdown";
import { GlobalContext } from "../../GlobalContext";
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import { toast } from 'react-toastify';

export default function AdminDashboard() {
  const { instance, accounts, inProgress } = useMsal()
  const history = useHistory()
  const orgId = sessionStorage.getItem('orgId')
  const [isLoading, setIsLoading] = useState(false)
  const ReportInventoryService = useReportInventoryService();
  const userType = sessionStorage.getItem('signedInUserType');
  let [signedInUserGroupsList, setSignedInUserGroupsList] = useState<any>([]);
  const [selectedProfile, setSelectedGroup] = useState<string>(Cookies.get('selectedProfile') || '');
  // const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
  const { getUserGroupTableLookupData } = useLooksUpData();
  const [mcUserGroupsList, setMcUserGroupsList] = useState<any[]>([]);
  const { globalVariable, setGlobalVariable } = useContext(GlobalContext);
  const [userAccessErrorMessage, setUserAccessErrorMessage] = useState<string>('');
  const { checkAccess, setPermissionsFromApiResponse } = useAccessControl();
  let [mcUsersList, setMcUsersList] = useState<UserList[]>([]);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState<any>(null);
  let nonStateUserId = sessionStorage.getItem('signedInUserId');

  let envPrefix = process.env.REACT_APP_ENV_PREFIX || '';

  interface UserList {
    userDetailsSid: string;
    orgUnitCd: string;
    email: string;
    userFirstName: string;
    userMiddleName: string;
    userLastName: string;
    userEmail: string;
    accountTypeLkpcd: string;
    phoneNumber1: string;
  }

  const fetchOrganizationUnitsTableLookupData = async () => {
    try {
      const response = await getUserGroupTableLookupData();
      if (response) {
        const { data } = response;

        if (Array.isArray(data)) {
          const filterAndMapData = data.map((code: any) => ({
            userGroupCode: `${code.userGroupCode}`,
            userGroupName: code.userGroupName,
          }));

          setMcUserGroupsList(filterAndMapData);
        } else {
          console.error('Expected data to be an array, but got:', data);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      if (selectedProfile) {
        try {
          const response = await ReportInventoryService.getAllUserGroupsFromDBByUserGroupId(selectedProfile);
          if (response) {
            setPermissionsFromApiResponse(response.data);
            setUserAccessErrorMessage('')
          } else {
            setUserAccessErrorMessage('No permissions found for this user.');
            toast.error('No permissions found for this user.');
          }
        } catch (error) {
            setUserAccessErrorMessage('An error occurred while fetching permissions.');
            toast.error('An error occurred while fetching permissions.');
        }
      }
    };

    fetchPermissions();
  }, [selectedProfile]);

  useEffect(() => {
    fetchOrganizationUnitsTableLookupData()
  }, []);

  useEffect(() => {
    if (accounts?.[0]?.username && mcUsersList.length > 0) {
      const loggedInUser = mcUsersList.find(user => user?.email?.toLowerCase() === accounts[0]?.username?.toLowerCase());

      if (loggedInUser) {
        const userDetails = {
          userId: loggedInUser.userDetailsSid,
          userFirstName: loggedInUser.userFirstName,
          userLastName: loggedInUser.userLastName,
          userEmail: loggedInUser.email,
          organizationUnitCode: loggedInUser.orgUnitCd,
        };
        sessionStorage.setItem('loginUserDetails', JSON.stringify(userDetails));
        setLoggedInUserDetails(userDetails);
      }
    } else {
      const nonStateUSerLogin = sessionStorage.getItem('signedInUserId');
      const loggedInUser = mcUsersList.find(user => user?.email?.toLowerCase() === nonStateUSerLogin?.toLowerCase());

      if (loggedInUser) {
        const userDetails = {
          userId: loggedInUser.userDetailsSid,
          userFirstName: loggedInUser.userFirstName,
          userLastName: loggedInUser.userLastName,
          userEmail: loggedInUser.email,
          organizationUnitCode: loggedInUser.orgUnitCd,
        };

        sessionStorage.setItem('loginUserDetails', JSON.stringify(userDetails));
        setLoggedInUserDetails(userDetails);
      }
    }
  }, [accounts, mcUsersList, nonStateUserId]);

  // useEffect(() => {
  //   ReportInventoryService.getAllUserGroupsFromDBByUserGroupId('DHHS_AR_NEMCIS_STT_TST-ADMIN')
  // }, []);

  const fetchAllMcUsersData = async () => {
    try {
      const response = await ReportInventoryService.getAllMcUsers();
      if (response) {
        setMcUsersList(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchAllMcUsersData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      if (userType === null && (!accounts?.[0]?.localAccountId)) {
        history.push('/sign-in');
      }
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [accounts, history, userType]);

  const fetchUserGroups = async () => {
    setIsLoading(true);

    if (accounts?.[0]?.username) {
      try {
        const userGroupsResponse = await getUserGroupTableLookupData();

        if (userGroupsResponse) {
          const userGroups = userGroupsResponse.data;
          console.log('User Groups from Table:', userGroups);

          if (inProgress === "none") {

            const account = accounts[0];
            const tokenRequest = {
              scopes: ['User.Read', 'Group.Read.All'],
              account: account
            };
            const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
            if (tokenResponse) {
              const accessToken = tokenResponse.accessToken;
              sessionStorage.setItem('msalToken', accessToken);

              const allGroupsFromAzure = await fetchAllMemberGroups('https://graph.microsoft.com/v1.0/me/memberOf', accessToken);

              const groupDisplayNames: string[] = allGroupsFromAzure.map((group: { displayName: string }) => group.displayName?.toLowerCase());
              const pagePermissions: any = {};
              userGroups.forEach((permission: any) => {
                const isWriteForPage = groupDisplayNames.includes(permission?.userGroupCode?.toLowerCase());
                pagePermissions[permission.userGroupCode] = isWriteForPage;
              });
              setGlobalVariable(pagePermissions);

              if (Object.keys(pagePermissions).length === 0) {
                setUserAccessErrorMessage('No permissions found for this user.');
              } else {
                setUserAccessErrorMessage('');

                // Filter groups with true values and create dropdown options
                // const trueGroups = Object.keys(pagePermissions).filter(group => pagePermissions[group]);
                // const dropdownOptions = trueGroups.map(group => {
                //   const matchingGroup = userGroups.find((code: any) => code.userGroupCode === group);
                //   return {
                //     value: group,
                //     label: matchingGroup ? matchingGroup.userGroupName : group,
                //   };
                // });
                // setSignedInUserGroupsList(dropdownOptions);
                // Create dropdown options using the display name from Azure AD
                const dropdownOptions = allGroupsFromAzure
                  .filter((group: { displayName: string }) => group.displayName.includes(envPrefix))
                  .map((group: { displayName: string, id: string }) => {
                    const label = envPrefix ? group.displayName.split(envPrefix+'-')[1] : group.displayName;
                    return {
                      value: `${group.displayName}`,
                      label: label,
                    };
                  });
                setSignedInUserGroupsList(dropdownOptions);
                console.log('AD groups--- ', dropdownOptions)

              }
            }
          } else {
            console.error('MSAL instance is not initialized.');
          }
        } else {
          setUserAccessErrorMessage('No permissions found for this user.');
          toast.error('No permissions found for this user.');
        }
      } catch (error) {
        console.error('Error fetching user groups:', error);
        setUserAccessErrorMessage('No permissions found for this user.');
        toast.error('No permissions found for this user.');
      } finally {
        setIsLoading(false);
      }
    } else if (nonStateUserId) {
      try {
        const loggedInUser = nonStateUserId ? mcUsersList.find(user => user?.email?.toLowerCase() === nonStateUserId?.toLowerCase()) : null;

        if (loggedInUser) {
          const userDetails = {
            userId: loggedInUser.userDetailsSid,
            userFirstName: loggedInUser.userFirstName,
            userLastName: loggedInUser.userLastName,
            userEmail: loggedInUser.email,
            organizationUnitCode: loggedInUser.orgUnitCd,
          };

          sessionStorage.setItem('loginUserDetails', JSON.stringify(userDetails));
          setLoggedInUserDetails(userDetails);
        }

        // Hardcode the group name
        const hardcodedGroup = `DHHS_AR_NEMCIS_MCO${envPrefix}-SUBMITTERS`;
        setSelectedGroup(hardcodedGroup)
        Cookies.set('selectedProfile', hardcodedGroup, { expires: 1 });
        const selectedLabel = hardcodedGroup.split(envPrefix+'-')[1];
        Cookies.set('selectedProfileLabel', selectedLabel, { expires: 1 });

      } catch (error) {
        console.error('Error fetching user groups:', error);
        setUserAccessErrorMessage('No permissions found for this user.');
        toast.error('No permissions found for this user.');
      } finally {
        setIsLoading(false);
      }
    } else if (mcUserGroupsList.length === 0 || !globalVariable) {
      setUserAccessErrorMessage('No permissions found for this user.');
      toast.error('No permissions found for this user.');
    }
  };

  useEffect(() => {
    fetchUserGroups();
  }, [inProgress]);

  async function fetchAllMemberGroups(url: string, accessToken: string, groups: any[] = []): Promise<any[]> {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    const newGroups = groups.concat(data.value);
    if (data['@odata.nextLink']) {
      return fetchAllMemberGroups(data['@odata.nextLink'], accessToken, newGroups);
    } else {
      return newGroups;
    }
  }

  const contractReportRouteFn = () => {
    history.push('/contact-report-invenroty');
  };

  const uploadReportRouteFn = async () => {
    console.log("Attempting to navigate to /upload-reports");
    try {
      history.push('/upload-reports');
      console.log("Navigation to /upload-reports successful");
    } catch (error) {
      console.error("Failed to navigate to /upload-reports", error);
    }
  };

  const remindersRouteFn = async () => {
    history.push('/reminders-notifications');
  };

  const dashboardRouteFn = async () => {
    history.push('/reports-dashboard');
  };
  const referenceRouteFn = async () => {
    history.push('/reference-data');
  };

  const userManagementRouteFn = async () => {
    history.push('/user-management');
  };

  const sftpUploadedRouteFn = async () => {
    history.push('/sftp-uploaded-reports');
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPermissionsFromApiResponse([]);
    Cookies.remove('selectedProfile');
    const selectedValue = e.target.value;
    setSelectedGroup(selectedValue);
    Cookies.set('selectedProfile', selectedValue, { expires: 1 });
    const selectedLabel = e.target.options[e.target.selectedIndex].text;


    if (selectedLabel !== 'Select Profile') {
      Cookies.set('selectedProfileLabel', selectedLabel, { expires: 1 });
    } else {
      Cookies.remove('selectedProfileLabel');
    }
  };

  const isNonStateUser = loggedInUserDetails?.organizationUnitCode !== "NEDHHS";
  const homePageStyle = userAccessErrorMessage || !Cookies.get('selectedProfile') || checkAccess('admin_dashboard', 'NOAC') ? { pointerEvents: 'none', opacity: 0.5 } : {};
  const isAlertsAndNotificationsDisabled = checkAccess('reminders_alerts', ['VWNA', 'NOAC']);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      ) : (
        <React.Fragment>
          <div className="flex-grow">
            <Navbar />
            <div className="header-container">
              <div className="page-subtitle-container">
                <PageSubTitle text={'Welcome to Nebraska Managed Care Insight System(NEMCIS)'} />
              </div>
              {!isNonStateUser && (

                <div>
                  {!signedInUserGroupsList ? (
                    <div style={{ color: 'red' }}>{userAccessErrorMessage}</div>
                  ) : (
                    <div className="dropdown-container">
                      <Dropdown
                        choices={signedInUserGroupsList}
                        emptyChoiceLabel="Select Profile"
                        name="reviewDecision"
                        label="Select Profile"
                        data-testid="profile_group"
                        value={selectedProfile}
                        onChange={handleGroupChange}
                        mandatoryLabel={true}
                      />
                    </div>
                  )}
                </div>
              )}

            </div>

            <div className="home_page_css" style={{ display: 'flex', justifyContent: 'center', ...homePageStyle, pointerEvents: homePageStyle.pointerEvents as React.CSSProperties['pointerEvents'] }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                  },
                  gap: 3,
                  justifyContent: 'center',

                  '& > :not(style)': {
                    m: 1,
                    width: { xs: '100%', sm: 260 },
                    height: { xs: 'auto', sm: 175 },
                    maxWidth: 260,
                    maxHeight: 175,
                    transition: 'box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      boxShadow: '0 0 20px rgba(0, 123, 255, 0.5)',
                    },
                  },
                  my: 0,
                  m: 0,
                }}
              >
                {/* Contract Report Inventory */}
                <div
                  onClick={contractReportRouteFn}
                  className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                >
                  <img src={reports} alt="lock" width='100px' className="self-start" />

                  <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                    <p className="leading-5 mb-3 text-black">Report Inventory</p>
                    <div className="flex gap-2">
                      <p className="text-xs">Track the scheduled and submitted reports</p>
                    </div>
                  </div>
                </div>

                {/* Upload Reports and Data */}
                {userType && (
                  <>
                    <div
                      onClick={uploadReportRouteFn}
                      className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                    >
                      <img src={uploadIcon} alt="lock" width='100px' className="self-start" />

                      <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                        <p className="leading-5 mb-3 text-black">Upload Reports and Data</p>
                        <div className="flex gap-2">
                          <p className="text-xs">Upload your required report or
                            data via the secure portal</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Reminders and Notifications */}
                <div
                  // style={isAlertsAndNotificationsDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                  onClick={remindersRouteFn}
                  className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                >
                  <img src={calendarIcon} alt="lock" width='100px' className="self-start" />

                  <div className="space-y-2 mb-16 flex flex-col justify-between ml-2 ml-2">
                    <p className="leading-5 mb-3 text-black">Alerts and Notifications</p>
                    <div className="flex gap-2">
                      <p className="text-xs">Alerts and Notifications for user actions</p>
                    </div>
                  </div>
                </div>

                {/* Dashboard */}
                {accounts?.[0]?.localAccountId && (
                  <>
                    <div
                      // onClick={dashboardRouteFn}
                      className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                    >
                      <img src={dashboardIcon} alt="lock" width='100px' className="self-start" />

                      <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                        <p className="leading-5 mb-3 text-black">Dashboard</p>
                        <div className="flex gap-2">
                          <p className="text-xs">Compare Overall Plan Compliance Performance</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Reference */}
                <div
                  onClick={referenceRouteFn}
                  className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                >
                  <img src={referenceIcon} alt="lock" width='100px' className="self-start" />

                  <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                    <p className="leading-5 mb-3 text-black">Report Master Catalog</p>
                    <div className="flex gap-2">
                      <p className="text-xs"> Click here to access the required reports, associated templates and submission requirements</p>
                    </div>
                  </div>
                </div>

                {/* User Management */}
                {accounts?.[0]?.localAccountId && (
                  <>
                    <div
                      onClick={userManagementRouteFn}
                      className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                    >
                      <img src={userManagementIcon} alt="lock" width='100px' className="self-start" />

                      <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                        <p className="leading-5 mb-3 text-black">User Management</p>
                        <div className="flex gap-2">
                          <p className="text-xs">Managing Users and their access
                            priveleges</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* SFTP Reports */}
                {accounts?.[0]?.localAccountId && (
                  <>
                    <div
                      onClick={sftpUploadedRouteFn}
                      className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                    >
                      <img src={uploadIcon} alt="lock" width='100px' className="self-start" />

                      <div className="space-y-2 mb-16 flex flex-col justify-between ml-2 ml-2">
                        <p className="leading-5 mb-3 text-black">Manage SFTP Reports</p>
                        <div className="flex gap-2">
                          <p className="text-xs">Link/Unlink the submitted SFTP reports</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              </Box>

            </div>
          </div>
          <div style={{ marginTop: 'auto' }}>
            <Footer />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
