import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import { FaArrowRight } from "react-icons/fa";
import { useMsal } from "@azure/msal-react";
import LockIcon from '../assets/images/lockIcon.png'
import Cookies from "js-cookie";
import { loginRequest } from "../../utils/authConfig";
import { useHistory } from "react-router-dom";
import { Link, Route } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ManageDevIcon from '../../img/manage_dev.png'
import arrow from '../../img/arrow.png'
import './signin-options.css'

// import {  LockIcon } from "../svgs/Index";
export default function SigninLandingPage() {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory();
  const historyRef = useRef(history); // Creating a ref for history

  useEffect(() => {
    const getTokenAndSetCookie = async () => {
      if (accounts && accounts.length > 0) {
        try {
          // Acquire token silently
          const silentResult = await instance.acquireTokenSilent({
            scopes: ["openid", "profile", "email"], // Add the required scopes
            account: accounts[0] // Use the first account
          });

          // Set the access token as a cookie
          const accessToken = silentResult.accessToken;
          // Cookies.set("token", accessToken);
          sessionStorage.setItem('msalToken', accessToken)

          // Redirect to the home page or any other desired route
          history.push('/');

          // Set loading state to false
          setIsLoading(false);
        } catch (error) {
          console.error("Token acquisition error:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    getTokenAndSetCookie();
  }, [instance, accounts]);

  const userType = sessionStorage.getItem('signedInUserType');

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      console.log('userType:', userType);
      if (userType || accounts?.[0]?.localAccountId) {
        history.push('/admin-dashboard');
      } else {
        history.push('/sign-in');
      }
      setIsLoading(false);
    }, 1000);
  }, [accounts, history, userType]);

  const adminSignInRouteFn = async () => {
    await instance
      .loginRedirect(loginRequest)
      .then((e) => {
        // history.push('/admin-dashboard');
      })
      .catch((e) => {
      });
  };

  return (
    <div className="flex flex-col overflow-x-hidden h-screen ">
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      ) :
        <React.Fragment>
          <Navbar />
          <div className="flex-grow">
            <div className="home_page_css">
              <div style={{ width: '100%' }} className={'row'}>
                <div className="col-12">
                  <h2 className="landing-heading-title">
                    Nebraska Managed Care Insight System
                  </h2>
                  <p className="font-base text-[16px] font-[#0F)F10] my-0">Please sign in to access the application.</p>
                </div>
              </div>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 1,
                    width: { xs: '100%', sm: 260 }, // 100% width on extra small screens, 260px on small screens and up
                    height: { xs: 'auto', sm: 175 }, // auto height on extra small screens, 175px on small screens and up
                    maxWidth: 260, // max width
                    maxHeight: 175, // max height
                  },
                  my: 1,
                  m: 0,
                }}
              >
                {/* {Admin sign in} */}
                <Route>
                  <Link
                    style={{
                      textDecoration: 'none',
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                    to={'/'}
                  >
                    <Paper
                      sx={{ border: 7, borderColor: '#bac6de', borderRadius: '26px' }}
                      elevation={3}
                      onClick={adminSignInRouteFn}
                    >
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', m: 1 }}>
                          State User Sign In
                        </Box>
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            position: 'relative',
                            left: '0%',
                          }}
                        >
                          <Avatar
                            alt="Mange Developers Icon"
                            sx={{ width: 60, height: 60 }}
                            src={ManageDevIcon}
                          />
                          <Box
                            sx={{
                              inlineSize: 130,
                              fontSize: 10,
                              letterSpacing: 0,
                              position: 'relative',
                              top: -60,
                              left: '35%',
                              height: '9vh',
                            }}
                          >
                            Click here to access NEMCIS
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            maxWidth: '50px',
                            position: 'relative',
                            left: '75%',
                            top: -25,
                          }}
                        >
                          <Avatar
                            alt="Arrow"
                            sx={{ width: 30, height: 25 }}
                            src={arrow}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Link>
                </Route>

                {/* {User sign in} */}
                <Route>
                  <Link
                    style={{
                      textDecoration: 'none',
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                    to={'/user-sign-in'}
                  >
                    <Paper
                      sx={{ border: 7, borderColor: '#bac6de', borderRadius: '26px' }}
                      elevation={3}
                    >
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', m: 1 }}>
                          Health Plan User Sign In
                        </Box>
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            position: 'relative',
                            left: '0%',
                          }}
                        >
                          <Avatar
                            alt="Mange Developers Icon"
                            sx={{ width: 60, height: 60 }}
                            src={ManageDevIcon}
                          />
                          <Box
                            sx={{
                              inlineSize: 130,
                              fontSize: 10,
                              letterSpacing: 0,
                              position: 'relative',
                              top: -60,
                              left: '35%',
                              height: '9vh',
                            }}
                          >
                            Click here to access NEMCIS
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            maxWidth: '50px',
                            position: 'relative',
                            left: '75%',
                            top: -25,
                          }}
                        >
                          <Avatar
                            alt="Arrow"
                            sx={{ width: 30, height: 25 }}
                            src={arrow}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Link>
                </Route>

                {/* {User sign up} */}
                {/* <Route>
                  <Link
                    style={{
                      textDecoration: 'none',
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                    // to={'/user-sign-up'}
                    to={''}
                  >
                    <Paper
                      sx={{ border: 7, borderColor: '#bac6de', borderRadius: '26px' }}
                      elevation={3}
                    >
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', m: 1 }}>
                          New User Sign up
                        </Box>
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            position: 'relative',
                            left: '0%',
                          }}
                        >
                          <Avatar
                            alt="Mange Developers Icon"
                            sx={{ width: 60, height: 60 }}
                            src={ManageDevIcon}
                          />
                          <Box
                            sx={{
                              inlineSize: 130,
                              fontSize: 10,
                              letterSpacing: 0,
                              position: 'relative',
                              top: -60,
                              left: '35%',
                              height: '9vh',
                            }}
                          >
                            Click here to new user sign up
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            maxWidth: '50px',
                            position: 'relative',
                            left: '75%',
                            top: -25,
                          }}
                        >
                          <Avatar
                            alt="Arrow"
                            sx={{ width: 30, height: 25 }}
                            src={arrow}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Link>
                </Route> */}
              </Box>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      }
    </div >
  );
};